<template>
  <div class="service page_width">
      <h5 class="title">about us</h5>
      <div class="text_msg">Trovima, the brainchild of technopreneurs, began its journey in 2013 as a digital marketplace. Since expanding into Singapore, Indonesia, and beyond in 2016, Trovima has thrived on its passion for gaming, emerging as a leading player in the industry.</div>
      <div class="text_msg">Trovima is dedicated to innovating for the benefit of the gaming community. Our mission is to provide a reliable digital platform for seamless buying and selling, fostering a comprehensive online marketplace for all things gaming-related.</div>
      <div class="text_msg">We are continually enhancing our platform to support digital game downloads, esports careers, and digital comics. Our unwavering commitment to innovation and service excellence drives us to create a vibrant global ecosystem for gamers.</div>
      <div class="text_msg"></div>
      <div class="text_msg"></div>

  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>